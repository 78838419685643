import React from "react";
// import GenuineCheck from "../components/GenuineCheck/GenuineCheck";
import MainSlide from "../components/MainSlide/MainSlide";
import ProductList from "../components/ProductList/ProductList";
import Genuine from "../components/GenuineCheck/Genuine";
import Helmet from "react-helmet";
import GenuineCheck from "../components/GenuineCheck/GenuineCheck";

const Home: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>Sareen Sports Industries - SS Cricket</title>
      </Helmet>
      <MainSlide/>
      <Genuine/>
      {/*<GenuineCheck/>*/}
      <ProductList/>
    </div>
  )
}

export default Home;
