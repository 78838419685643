import React, { Component } from "react";
import {Navbar, Nav, NavDropdown, Container} from "react-bootstrap";
import logo from "../assets/images/logo.png"

class Header extends Component{
    render() {
        return(
            <Container fluid={true} className="headers pl-0">
                <Container fluid={false} className="pl-0 pr-0 ">
                    <Navbar expand="lg" sticky="top">
                        <Navbar.Brand className="logo" href="/home"><img src={logo} alt={logo}/></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="text-center">
                                <Nav.Link href="/home">Home</Nav.Link>
                                <NavDropdown title="Products" id="collasible-nav-dropdown">
                                    <div className="nav-text">Bats</div>
                                    <NavDropdown.Item href="/bats/retro-range">Retro Range</NavDropdown.Item>
                                    <NavDropdown.Item href="/bats/vintage-classic-collection">Vintage Collection</NavDropdown.Item>
                                    <NavDropdown.Item href="/bats/tonrange">TON Bats</NavDropdown.Item>
                                    <NavDropdown.Item href="/bats/ssrange">SS Range</NavDropdown.Item>
                                    <NavDropdown.Item href="/bats/master-range">Master Range</NavDropdown.Item>
                                    <NavDropdown.Item href="/bats/white-edition/">White Range</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="/kitbags">Kitbags</NavDropdown.Item>
                                    <NavDropdown.Item href="/helmets">Helmets</NavDropdown.Item>
                                    <NavDropdown.Item href="/cricket-pads">Cricket Pads</NavDropdown.Item>
                                    <NavDropdown.Item href="/shoes">Shoes</NavDropdown.Item>
                                    <NavDropdown.Item href="/balls">Balls</NavDropdown.Item>
                                    <NavDropdown.Item href="/accessories">Accessories</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link href="/ss-family">SS Family</Nav.Link>
                                <Nav.Link href="/about-us">About us</Nav.Link>
                                <Nav.Link href="/contact-us">Contact us</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Container>
            </Container>
        )
    }
}

export default Header;