import React, {Component} from "react";
import {Container} from "react-bootstrap";
import whiteeditioncover from "../../../assets/images/cover ss page.png";

class WhiteEditionCover extends Component{
    render() {
        return(
            <Container fluid={true} className="mainslide text-center pl-0 pr-0 ml-0">
                <img src={whiteeditioncover} alt={whiteeditioncover}/>
            </Container>
        )
    }
}

export default WhiteEditionCover;