import React from "react";
import {Container, Col, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";

type ProductProps = {
    metaTitle: string
    coverPhoto: string
    breadcrumbProductCat: string
    breadcrumbProductCatUrl: string
    breadcrumbProduct: string
    productImage: string
    productImageAlt: string
    productTitle: string
    description: string[]
}

function Product(props: ProductProps) {
    return(
        <Container fluid={true} className="mainslide">
            <Helmet>
                <title>{props.metaTitle}</title>
            </Helmet>
            <img src={props.coverPhoto} alt="SS Cricket Equipment dealer in Sri Lanka & Product Verification"/>
            <div className="hr">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/home">Home</a></li>
                        <li className="breadcrumb-item"><a href={props.breadcrumbProductCatUrl}>{props.breadcrumbProductCat}</a></li>
                        <li className="breadcrumb-item active" aria-current="page">{props.breadcrumbProduct}</li>
                    </ol>
                </nav>
            </div>
            <Container fluid={true} className="product-section">
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} className="sin-product-img mt-md-5 mt-lg-5 mb-md-5 mb-lg-5">
                        <img src={props.productImage} alt={props.productImageAlt}/>
                    </Col>
                    <Col xs={12} sm={12} md={7} lg={7}>
                        <div className="sn-product-title text-left hr-tag">
                            <h1>{props.productTitle}</h1>
                        </div>
                        <div className="sn-product-desc">
                            <h2>PRODUCT DESCRIPTION</h2>
                        </div>
                        <div className="mb-3">
                            <ul>
                                {
                                    props.description.map((description, index) => {
                                        return <li key={index}>{description}</li>
                                    })
                                }
                            </ul>
                        </div>
                        <div className="hr-tag">
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Product;