import React, {Component} from "react";
import {Container, Col, Row} from "react-bootstrap";
import cover from "../../assets/images/products/batcover.jpg";
import img1 from "../../assets/images/products/bats/SS/07.ton_47.jpg";
import {Helmet} from "react-helmet";
import BatFooter from "../../components/BatFooter/BatFooter";
// import { FacebookShareButton, TwitterShareButton, EmailShareButton, InstapaperShareButton, FacebookIcon, TwitterIcon, EmailIcon, InstapaperIcon } from "react-share";

class TON47 extends Component {
    render() {
        return (
            <Container fluid={true} className="mainslide">
                <Helmet>
                    <title>TON 47 English Willow Bat - SS Cricket</title>
                </Helmet>
                <img src={cover} alt={cover}/>
                <div className="hr">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/home">Home</a></li>
                            <li className="breadcrumb-item"><a href="/ssrange">SS Range</a></li>
                            <li className="breadcrumb-item active" aria-current="page">TON 47 English Willow Bat
                            </li>
                        </ol>
                    </nav>
                </div>
                <Container fluid={true} className="product-section">
                    <Row>
                        <Col xs={12} sm={12} md={5} lg={5} className="sin-product-img mt-md-5 mt-lg-5 mb-md-5 mb-lg-5">
                            <img src={img1} alt={img1}/>
                        </Col>
                        <Col xs={12} sm={12} md={7} lg={7}>
                            <div className="sn-product-title text-left hr-tag">
                                <h1>TON 47 ENGLISH WILLOW BAT</h1>
                            </div>
                            <div className="sn-product-desc">
                                <h2>PRODUCT DESCRIPTION</h2>
                            </div>
                            <div className="mb-3">
                                <ul>
                                    <li>Hand Selected Grade 3 Willow</li>
                                    <li>Round Handle</li>
                                    <li>Specially designed Aqua Grip with Embossed Sunridges</li>
                                    <li>Combination of Sarawak cane Handle</li>
                                    <li>Concave Edge</li>
                                    <li>Bat Cover</li>
                                </ul>
                            </div>
                            <div className="hr-tag">
                                {/*<div>*/}
                                {/*    <FacebookShareButton url="/bats/retro/ss-classic-supreme"/>*/}
                                {/*    <FacebookIcon size={32} round />*/}
                                {/*</div>*/}
                            </div>
                        </Col>
                    </Row>
                    <Container fluid={true}>
                        <BatFooter/>
                    </Container>
                </Container>
            </Container>
        )
    }
}

export default TON47;