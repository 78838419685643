import React from "react";
import { Container} from "react-bootstrap";
import mainphoto from "../../assets/images/Web Cover.png";

class MainSlide extends React.Component{
    render(){
        return(
            <Container fluid={true}>
                <div className="mainslide text-center ">
                   <a href=""><img src={mainphoto} alt={mainphoto}/></a>
                </div>
            </Container>
        )
    }
}

export default MainSlide;

// import React from "react";
// import Slider from 'infinite-react-carousel';
//
// const SimpleSlider = () => (
//     <Slider dots>
//         <div>
//             <h3>1</h3>
//         </div>
//         <div>
//             <h3>2</h3>
//         </div>
//         <div>
//             <h3>3</h3>
//         </div>
//         <div>
//             <h3>4</h3>
//         </div>
//         <div>
//             <h3>5</h3>
//         </div>
//     </Slider>
// );
//
// export default SimpleSlider;
