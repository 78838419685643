import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import gladiator from "../../assets/images/products/gladiator.png";
import retro from "../../assets/images/products/retro.png";
import vintage from "../../assets/images/products/classic.png";
import master from "../../assets/images/products/master.png";
import whiteedition from "../../assets/images/products/white-edition.png";
import ton from "../../assets/images/products/ton.png";
import kitbag from "../../assets/images/products/kitbag.png";
import helmet from "../../assets/images/products/helmet.png";
import pads from "../../assets/images/products/pads.png";
import shoes from "../../assets/images/products/shoes.png";
import balls from "../../assets/images/products/ball.png";

class ProductList extends React.Component{
    render() {
        return(
            <Container fluid={true} className="bg-product">
                <div className="text-center title-product">
                    <h1>PRODUCTS RANGE</h1>
                </div>
                <Row>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border align-content-center">
                        <a href="/bats/retro-range"><img src={retro} alt={retro}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/retro-range">SS Retro Range</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="/bats/vintage-classic-collection"><img src={vintage} alt={vintage}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/vintage-classic-collection">SS Vintage Classic Range</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="/bats/tonrange/"><img src={ton} alt={ton}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/tonrange/">TON Bats</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="/bats/ssrange"><img src={gladiator} alt={gladiator}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/ssrange">SS Range</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="/bats/master-range/"><img src={master} alt={master}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/master-range/">SS Master Range</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="/bats/white-edition/"><img src={whiteedition} alt={whiteedition}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/white-edition/">SS White Range</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="/kitbags"><img src={kitbag} alt={kitbag}/></a>
                        <div className="title-p text-center">
                            <a href="/kitbags">Kitbags</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="/helmets"><img src={helmet} alt={helmet}/></a>
                        <div className="title-p text-center">
                            <a href="/helmets">Helmets</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="#"><img src={pads} alt={pads}/></a>
                        <div className="title-p text-center">
                            <a href="#">Pads</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={shoes} alt={shoes}/></a>
                        <div className="title-p text-center">
                            <a href="#">Shoes</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={balls} alt={balls}/></a>
                        <div className="title-p text-center">
                            <a href="#">Balls</a>
                        </div>
                    </Col>
                    {/*<Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">*/}
                    {/*    <a href="#"><img src={shoes} alt={shoes}/></a>*/}
                    {/*    <div className="title-p text-center">*/}
                    {/*        <a href="#">Accessories</a>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                </Row>
                <Container fluid={true} className="ml-0 bottombg">
                    <Container fluid={false}>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <div className="text-center title-bt">
                                    <h1>The SS Legacy</h1>
                                </div>
                                <div className="mb-5 body-txt">
                                    <p>Sareen Sports Industries was started in the year 1969 by Mr. N.K. Sareen under the inspiration of his father Mr. M.L. Sareen, Sareen Sports Industries entered in the Indian market with a great hard work and dedication to launch their registered Trade-Mark SS SUNRIDGES in the year 1976. The company started export of cricket bats in 1979 to UK and Australia. Sareen Sports Industries manufacturers quality cricket equipments for meeting the requirements of national and international markets. The company also added on the production of Cricket Balls and Soft Leather Protective Equipments under the flagship of “SS SUNRIDGES” in the year 1985.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Container>
        )
    }
}

export default ProductList;