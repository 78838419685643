export const FE_ROUTES = {
    HOME: "/home",
    SSFAMILY: "/ss-family",
    ABOUTUS: "/about-us",
    CONTACTUS: "/contact-us",
    RETRO: "/bats/retro-range",
    RETROCLASSICSUPREME: "/bats/retro/ss-classic-supreme",
    RETROCLASSICPOWERPLUS: "/bats/retro/ss-classic-power-plus",
    RETROCLASSICMAXPOWER: "/bats/retro/ss-classic-max-power",
    RETROCLASSICSUPER: "/bats/retro/ss-classic-super",
    RETROCLASSICGUTSY: "/bats/retro/ss-classic-gutsy",
    RETROCLASSICKW: "/bats/retro/ss-classic-kw",
    // Vintage Classic
    VINTAGECLASSIC: "/bats/vintage-classic-collection",
    VINTAGE1: "/bats/vintage/ew-1.0",
    VINTAGE2: "/bats/vintage/ew-2.0",
    VINTAGE3: "/bats/vintage/ew-3.0",
    VINTAGE4: "/bats/vintage/ew-4.0",
    // SS Range
    SSRANGE: "/bats/ssrange",
    GLADIATOR: "/bats/ssrange/ew-gladiator",
    MAXIMUS: "/bats/ssrange/ew-maximus",
    LIMITEDEDITION: "/bats/ssrange/ew-limited-edition",
    PROFESSIONAL: "/bats/ssrange/ew-professional",
    RANGER: "/bats/ssrange/ew-ranger",
    EWIBAT: "/bats/ssrange/ew-i-bat",
    EWTON47: "/bats/ssrange/ew-ton47",


    //TON Range
    TONRANGE: "/bats/tonrange/",


    // Master Range
    MASTERRANGE: "/bats/master-range/",


    // White Edition
    WHITEEDITION: "/bats/white-edition/",


    // Kit Bags
    KITBAGS: '/kitbags',


    // Helmets
    HELMETS: '/helmets',

    ROOT: "/",
};

export const API_ROUTES = {};