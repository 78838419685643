import React, {Component} from "react";
import {Container} from "react-bootstrap";
import kitbagscover from "../../../assets/images/cover kitbags.png";

class KitBagsCover extends Component{
    render() {
        return(
            <Container fluid={true} className="mainslide text-center pl-0 pr-0 ml-0">
                <img src={kitbagscover} alt={kitbagscover}/>
            </Container>
        )
    }
}

export default KitBagsCover;