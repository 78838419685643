import React from "react";
import { Route, Switch } from "react-router";
import indexRoutes from "../routes";
import { Container } from "react-bootstrap";
import Footer from "./Footer";
import Header from "./Header";

class PageLayout extends React.Component {
  render() {
    return (
      <Container fluid={true} className="headers">
        <Container>
          <Header/>
        </Container>
        <Switch>
          {indexRoutes.map((route, i) => {
            return (
              <Route key={i} path={route.path} component={route.component}/>
            );
          })}

        </Switch>

        <Footer/>
      </Container>
    );
  }
}

export default PageLayout;
