import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import {Helmet} from "react-helmet";
import WhiteEditionCover from "../../components/Bats/WhiteEditionCover/WhiteEditionCover";
import wblack from "../../assets/images/products/bats/White Edition/01.english_willow_white_edition_black.png";
import wred from "../../assets/images/products/bats/White Edition/02.english_willow_white_edition_red.png";
import wgold from "../../assets/images/products/bats/White Edition/03.english_willow_white_edition_gold.png";
import wgreen from "../../assets/images/products/bats/White Edition/04.english_willow_white_edition_green.png";
import wblue from "../../assets/images/products/bats/White Edition/05.english_willow_white_edition_blue.png";
import wpink from "../../assets/images/products/bats/White Edition/06.english_willow_white_edition_pink.png";
import kwblack from "../../assets/images/products/bats/White Edition/07.kashmir_willow_white_edition_black.png";
import kwred from "../../assets/images/products/bats/White Edition/08.kashmir_willow_white_edition_red.png";
import kwgold from "../../assets/images/products/bats/White Edition/09.kashmir_willow_white_edition_gold.png";
import kwgreen from "../../assets/images/products/bats/White Edition/10.kashmir_willow_white_edition_green.png";
import kwblue from "../../assets/images/products/bats/White Edition/11.kashmir_willow_white_edition_blue.png";
import kwpink from "../../assets/images/products/bats/White Edition/12.kashmir_willow_white_edition_pink.png";

class WhiteEdition extends Component{
    render() {
        return(
            <Container fluid={true}>
                <Helmet>
                    <title>SS White Edition - SS Cricket</title>
                </Helmet>
                <div>
                    <WhiteEditionCover/>
                </div>
                <div className="text-center product-title hr">
                    <h1>SS WHITE EDITION</h1>
                    <div className="pb-1 subtitle">
                        <h5>#BestNeverStops</h5>
                    </div>
                    <div className="pb-3 subtitle">
                        <h5>#LeadershipThroughQuality</h5>
                    </div>
                </div>
                <Row>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border align-content-center">
                        <a href="#"><img src={wblack} alt={wblack}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS White Edition Black English Willow Cricket Bat </a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={wred} alt={wred}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS White Edition Red English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={wgold} alt={wgold}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS White Edition Gold English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={wgreen} alt={wgreen}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS White Edition Green English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="#"><img src={wblue} alt={wblue}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS White Edition Blue English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={wpink} alt={wpink}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS White Edition Pink English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={kwblack} alt={kwblack}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS White Edition Black Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={kwred} alt={kwred}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS White Edition Red Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="#"><img src={kwgold} alt={kwgold}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS White Edition Gold Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={kwgreen} alt={kwgreen}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS White Edition Green Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={kwblue} alt={kwblue}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS White Edition Blue Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={kwpink} alt={kwpink}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS White Edition Pink Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                </Row>
                <div className="mb-5"> </div>
            </Container>
        )
    }
}

export default WhiteEdition;