import React, {Component} from "react";
import {Col, Container} from "react-bootstrap";
import coverimg from "../assets/images/cover-ss (1).png";
import Helmet from "react-helmet";

class ContactUs extends Component {
    render() {
        return (
            <Container fluid={true} className="mainslide text-center pl-0 pr-0 ml-0 bg-color">
                <Helmet>
                    <title>Contact Us - SS Cricket</title>
                </Helmet>
                <div>
                    <img src={coverimg} alt={coverimg}/>
                </div>
                <div className="text-center product-title hr pb-2">
                    <h1>CONTACT US</h1>
                </div>
                <div className="wrapper">
                    <div className="text-left">
                        <h1>Sareen Sports Industries</h1>
                        <span>
                            Salarpur, Mawana Road,<br/>
                            Near Translam Academy,<br/>
                            Meerut – 250001 (U.P.)<br/>
                            Contact No : +91(0)-121-2623953, +91(0)-121-2623954<br/>
                            Customer Care :+91(0)-9358404790,   +91(0)-9837042773
                        </span>
                        <div className="mb-3"> </div>
                    </div>
                    <div className="hr-tag"></div>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <div className="pb-1 subtitle">
                            <h5>Thank you for visiting out our website. If you would like to get in touch with our team
                                “simply” fill out the form below</h5>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <div className="wrap-input100 validate-input" data-validate="Please enter your name">
                            <input className="input100" type="text" name="name" placeholder="Full Name"/>
                            <span className="focus-input100"> </span>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <div className="wrap-input100 validate-input" data-validate="Please enter email: e@a.x">
                            <input className="input100" type="text" name="email" placeholder="Email"/>
                            <span className="focus-input100"> </span>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <div className="wrap-input100 validate-input"
                             data-validate="Please enter contact number: e@a.x">
                            <input className="input100" type="tel" maxLength={13} name="tel"
                                   placeholder="Contact Number"/>
                            <span className="focus-input100"> </span>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <div className="wrap-input100 validate-input" data-validate="Please enter your message">
                            <textarea className="input100" name="message" placeholder="Your Message"></textarea>
                            <span className="focus-input100"> </span>
                        </div>
                    </Col>
                    <div className="container-contact100-form-btn text-left align-content-lg-end">
                        <button className="contact100-form-btn">
                            Send Email
                        </button>
                    </div>
                </div>
                <div className="mb-5 bg-color"> </div>
            </Container>
        )
    }
}

export default ContactUs;