import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import photo from "../../assets/images/mainslide/cover-2.png";
import {Helmet} from "react-helmet";
import reserveedition from "../../assets/images/products/bats/TON/01.english_willow_ton_reserve_edition.png";
import playeredition from "../../assets/images/products/bats/TON/02.english_willow_ton_player_edition_1.png";
import goldedition from "../../assets/images/products/bats/TON/03.english_willow_ton_gold_edition.png";
import legendew from "../../assets/images/products/bats/TON/04.english_willow_ton_legend.png";
import silveredi from "../../assets/images/products/bats/TON/05.english_willow_ton_silver_edition.png";
import vertu from "../../assets/images/products/bats/TON/06.english_willow_ton_vertu.png";
import supreme from "../../assets/images/products/bats/TON/07.english_willow_ton_supreme.png";
import slasher from "../../assets/images/products/bats/TON/08.english_willow_ton_slasher.png";
import tongutsy from "../../assets/images/products/bats/TON/09.english_willow_ton_gutsy.png";
import tonsuper from "../../assets/images/products/bats/TON/10english_willow_ton_super.png";
import tonglory from "../../assets/images/products/bats/TON/11.english_willow_ton_glory.png";
import tonelite from "../../assets/images/products/bats/TON/12.english_willow_ton_elite.png";
import ton999 from "../../assets/images/products/bats/TON/13.english_willow_ton_999.png";
import tonclassic from "../../assets/images/products/bats/TON/14.english_willow_ton_classic.png";
import kwreserveedi from "../../assets/images/products/bats/TON/15.ton_kw_reserve_edition.png";
import kwrevolution from "../../assets/images/products/bats/TON/16.ton_kw_revolution.png";
import kwpowerplus from "../../assets/images/products/bats/TON/17.ton_kw_power_plus.png";
import kwblaster from "../../assets/images/products/bats/TON/18.kashmir_willow_ton_blaster.png";
import kwmaximus from "../../assets/images/products/bats/TON/19.ton_kw_maximus.png";
import kwmaxpower from "../../assets/images/products/bats/TON/20.ton_kw_maxpower.png";
import kwtontennis from "../../assets/images/products/bats/TON/21.ton_kw_tennis.png";

class TONRange extends Component{
    render() {
        return(
            <Container fluid={true}>
                <Helmet>
                    <title>TON Range - SS Cricket</title>
                </Helmet>
                <div className="mainslide text-center pl-0 pr-0 ml-0">
                    <a href=""><img src={photo} alt={photo}/></a>
                </div>
                <div className="text-center product-title hr">
                    <h1>TON RANGE</h1>
                    <div className="pb-1 subtitle">
                        <h5>#BestNeverStops</h5>
                    </div>
                    <div className="pb-3 subtitle">
                        <h5>#LeadershipThroughQuality</h5>
                    </div>
                </div>
                <Row>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border align-content-center">
                        <a href="#"><img src={reserveedition} alt={reserveedition}/></a>
                        <div className="title-p text-center">
                            <a href="#">TON Reserve Edition English Willow Cricket Bat </a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={playeredition} alt={playeredition}/></a>
                        <div className="title-p text-center">
                            <a href="#">TON Player Edition English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={goldedition} alt={goldedition}/></a>
                        <div className="title-p text-center">
                            <a href="#">TON Gold Edition English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={legendew} alt={legendew}/></a>
                        <div className="title-p text-center">
                            <a href="#">TON Legend English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="#"><img src={silveredi} alt={silveredi}/></a>
                        <div className="title-p text-center">
                            <a href="#">TON Silver Edition English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={vertu} alt={vertu}/></a>
                        <div className="title-p text-center">
                            <a href="#">TON Vertu English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={supreme} alt={supreme}/></a>
                        <div className="title-p text-center">
                            <a href="#">TON Supreme English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={slasher} alt={slasher}/></a>
                        <div className="title-p text-center">
                            <a href="#">TON Slasher English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="#"><img src={tongutsy} alt={tongutsy}/></a>
                        <div className="title-p text-center">
                            <a href="#">TON Gutsy English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={tonsuper} alt={tonsuper}/></a>
                        <div className="title-p text-center">
                            <a href="#">TON Super English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={tonglory} alt={tonglory}/></a>
                        <div className="title-p text-center">
                            <a href="#">TON Glory English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={tonelite} alt={tonelite}/></a>
                        <div className="title-p text-center">
                            <a href="#">TON Elite English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="#"><img src={ton999} alt={ton999}/></a>
                        <div className="title-p text-center">
                            <a href="#">TON 999 English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={tonclassic} alt={tonclassic}/></a>
                        <div className="title-p text-center">
                            <a href="#">TON Classic English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={kwreserveedi} alt={kwreserveedi}/></a>
                        <div className="title-p text-center">
                            <a href="#">TON Reserve Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={kwrevolution} alt={kwrevolution}/></a>
                        <div className="title-p text-center">
                            <a href="#">TON Revolution Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="#"><img src={kwpowerplus} alt={kwpowerplus}/></a>
                        <div className="title-p text-center">
                            <a href="#">TON Power Plus Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={kwblaster} alt={kwblaster}/></a>
                        <div className="title-p text-center">
                            <a href="#">TON Blaster Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={kwmaximus} alt={kwmaximus}/></a>
                        <div className="title-p text-center">
                            <a href="#">TON Maximus Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={kwmaxpower} alt={kwmaxpower}/></a>
                        <div className="title-p text-center">
                            <a href="#">TON Max Power Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="#"><img src={kwtontennis} alt={kwtontennis}/></a>
                        <div className="title-p text-center">
                            <a href="#">TON Tennis Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                </Row>
                <div className="mb-5"> </div>
            </Container>
        )
    }
}

export default TONRange;