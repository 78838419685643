import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import photo from "../../assets/images/cover ss page.png";
import {Helmet} from "react-helmet";
import gladiator from "../../assets/images/products/bats/SS/01.-gladiator.png";
import MaxiMus from "../../assets/images/products/bats/SS/02.-maximus.png";
import LimitedEdition from "../../assets/images/products/bats/SS/03.-limited_edition.png";
import Professional from "../../assets/images/products/bats/SS/04.professional.png";
import ranger from "../../assets/images/products/bats/SS/05.ranger.png";
import i from "../../assets/images/products/bats/SS/06.i_bat.png";
import ton from "../../assets/images/products/bats/SS/07.ton_47.png";
import heritage from "../../assets/images/products/bats/SS/08.heritage.png";
import thor from "../../assets/images/products/bats/SS/09.thor.png";
import sirrichards from "../../assets/images/products/bats/SS/10.sir_richard.png";
import mammoth from "../../assets/images/products/bats/SS/12.-ew_mammoth.png";
import viper from "../../assets/images/products/bats/SS/13.viper.png";
import orange from "../../assets/images/products/bats/SS/14.orange.png";
import premium from "../../assets/images/products/bats/SS/15.premium.png";
import blast from "../../assets/images/products/bats/SS/16.blast.png";
import supremo from "../../assets/images/products/bats/SS/17.supremo.png";
import waves from "../../assets/images/products/bats/SS/18.waves.png";
import dynasty from "../../assets/images/products/bats/SS/19dynasty.png";
import superpower from "../../assets/images/products/bats/SS/20.super_power.png";
import tiger from "../../assets/images/products/bats/SS/21.tiger.png";
import powerplay from "../../assets/images/products/bats/SS/22.power_play.png";
import gladiatorkw from "../../assets/images/products/bats/SS/23.gladiator_kw.png";
import magnum from "../../assets/images/products/bats/SS/24.magnum.png";
import ikw from "../../assets/images/products/bats/SS/25.i_bat_kw.png";
import supersixes from "../../assets/images/products/bats/SS/26.super_sixes_kw.png";
import gutsykw from "../../assets/images/products/bats/SS/27.gurtsy_kw.jpg";
import extremekw from "../../assets/images/products/bats/SS/28.extreme_kw.png";
import rangerkw from "../../assets/images/products/bats/SS/29.ranger_kw.png";
import yuvikw from "../../assets/images/products/bats/SS/30.yuvi_20_20_kw.png";
import sangakkarakw from "../../assets/images/products/bats/SS/31.sangakkara_kw.png";
import cannonkw from "../../assets/images/products/bats/SS/32.magnet_kw.png";
import clubvellum from "../../assets/images/products/bats/SS/33.club_vellum_kw.png";
import softpro from "../../assets/images/products/bats/SS/34.soft_pro_kw.png";

class SSRange extends Component{
    render() {
        return(
            <Container fluid={true}>
                <Helmet>
                    <title>SS Range - SS Cricket</title>
                </Helmet>
                <div className="mainslide text-center pl-0 pr-0 ml-0">
                    <a href=""><img src={photo} alt={photo}/></a>
                </div>
                <div className="text-center product-title hr">
                    <h1>SS RANGE</h1>
                    <div className="pb-1 subtitle">
                        <h5>#BestNeverStops</h5>
                    </div>
                    <div className="pb-3 subtitle">
                        <h5>#LeadershipThroughQuality</h5>
                    </div>
                </div>
                <Row>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border align-content-center">
                        <a href="/bats/ssrange/ew-gladiator"><img src={gladiator} alt={gladiator}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/ssrange/ew-gladiator">SS Gladiator English Willow Cricket Bat </a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="/bats/ssrange/ew-maximus"><img src={MaxiMus} alt={MaxiMus}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/ssrange/ew-maximus">SS MaxiMus English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="/bats/ssrange/ew-limited-edition"><img src={LimitedEdition} alt={LimitedEdition}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/ssrange/ew-limited-edition">SS Limited Edition English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="/bats/ssrange/ew-professional"><img src={Professional} alt={Professional}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/ssrange/ew-professional">SS Professional English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    {/*<Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">*/}
                    {/*    <a href="/bats/retro/ss-classic-gutsy"><img src={ranger} alt={ranger}/></a>*/}
                    {/*    <div className="title-p text-center">*/}
                    {/*        <a href="/bats/retro/ss-classic-gutsy">SS Ranger English Willow Cricket Bat</a>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                    {/*<Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">*/}
                    {/*    <a href="bats/retro/ss-classic-kw"><img src={i} alt={i}/></a>*/}
                    {/*    <div className="title-p text-center">*/}
                    {/*        <a href="bats/retro/ss-classic-kw">SS English Willow I Cricket Bat</a>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                    {/*<Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">*/}
                    {/*    <a href="bats/retro/ss-classic-kw"><img src={i} alt={i}/></a>*/}
                    {/*    <div className="title-p text-center">*/}
                    {/*        <a href="bats/retro/ss-classic-kw">SS English Willow I Cricket Bat</a>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                    {/*<Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">*/}
                    {/*    <a href="bats/retro/ss-classic-kw"><img src={i} alt={i}/></a>*/}
                    {/*    <div className="title-p text-center">*/}
                    {/*        <a href="bats/retro/ss-classic-kw">SS English Willow I Cricket Bat</a>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="/bats/ssrange/ew-ranger"><img src={ranger} alt={ranger}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/ssrange/ew-ranger">SS Ranger English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="/bats/ssrange/ew-i-bat"><img src={i} alt={i}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/ssrange/ew-i-bat">SS English Willow I Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="/bats/ssrange/ew-ton47"><img src={ton} alt={ton}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/ssrange/ew-ton47">TON 47 English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="/bats/retro/ss-classic-kw"><img src={heritage} alt={heritage}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/retro/ss-classic-kw">SS Heritage English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="/bats/retro/ss-classic-gutsy"><img src={thor} alt={thor}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/retro/ss-classic-gutsy">SS Thor English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="/bats/retro/ss-classic-kw"><img src={sirrichards} alt={sirrichards}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/retro/ss-classic-kw">SS Sir-Richards English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="bats/retro/ss-classic-kw"><img src={mammoth} alt={mammoth}/></a>
                        <div className="title-p text-center">
                            <a href="bats/retro/ss-classic-kw">SS English Willow Mammoth Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="bats/retro/ss-classic-kw"><img src={viper} alt={viper}/></a>
                        <div className="title-p text-center">
                            <a href="bats/retro/ss-classic-kw">SS Viper English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="/bats/retro/ss-classic-gutsy"><img src={orange} alt={orange}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/retro/ss-classic-gutsy">SS Orange English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="bats/retro/ss-classic-kw"><img src={premium} alt={premium}/></a>
                        <div className="title-p text-center">
                            <a href="bats/retro/ss-classic-kw">SS Premium English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="bats/retro/ss-classic-kw"><img src={blast} alt={blast}/></a>
                        <div className="title-p text-center">
                            <a href="bats/retro/ss-classic-kw">SS Blast English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="bats/retro/ss-classic-kw"><img src={supremo} alt={supremo}/></a>
                        <div className="title-p text-center">
                            <a href="bats/retro/ss-classic-kw">SS Supremo English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="/bats/retro/ss-classic-gutsy"><img src={waves} alt={waves}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/retro/ss-classic-gutsy">SS Waves English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="bats/retro/ss-classic-kw"><img src={dynasty} alt={dynasty}/></a>
                        <div className="title-p text-center">
                            <a href="bats/retro/ss-classic-kw">SS  Dynasty English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="bats/retro/ss-classic-kw"><img src={superpower} alt={superpower}/></a>
                        <div className="title-p text-center">
                            <a href="bats/retro/ss-classic-kw">SS Super Power English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="bats/retro/ss-classic-kw"><img src={tiger} alt={tiger}/></a>
                        <div className="title-p text-center">
                            <a href="bats/retro/ss-classic-kw">SS Tiger English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="/bats/retro/ss-classic-gutsy"><img src={powerplay} alt={powerplay}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/retro/ss-classic-gutsy">SS Power Play English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="bats/retro/ss-classic-kw"><img src={gladiatorkw} alt={gladiatorkw}/></a>
                        <div className="title-p text-center">
                            <a href="bats/retro/ss-classic-kw">SS Gladiator Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="bats/retro/ss-classic-kw"><img src={magnum} alt={magnum}/></a>
                        <div className="title-p text-center">
                            <a href="bats/retro/ss-classic-kw">SS Magnum English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="bats/retro/ss-classic-kw"><img src={ikw} alt={ikw}/></a>
                        <div className="title-p text-center">
                            <a href="bats/retro/ss-classic-kw">SS I Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="/bats/retro/ss-classic-gutsy"><img src={supersixes} alt={supersixes}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/retro/ss-classic-gutsy">SS Super Sixes Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="bats/retro/ss-classic-kw"><img src={gutsykw} alt={gutsykw}/></a>
                        <div className="title-p text-center">
                            <a href="bats/retro/ss-classic-kw">SS Gutsy Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="bats/retro/ss-classic-kw"><img src={extremekw} alt={extremekw}/></a>
                        <div className="title-p text-center">
                            <a href="bats/retro/ss-classic-kw">SS Extreme Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="bats/retro/ss-classic-kw"><img src={rangerkw} alt={rangerkw}/></a>
                        <div className="title-p text-center">
                            <a href="bats/retro/ss-classic-kw">SS Ranger Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="/bats/retro/ss-classic-gutsy"><img src={yuvikw} alt={yuvikw}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/retro/ss-classic-gutsy">SS Yuvi 20/20 Kashmir English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="bats/retro/ss-classic-kw"><img src={sangakkarakw} alt={sangakkarakw}/></a>
                        <div className="title-p text-center">
                            <a href="bats/retro/ss-classic-kw">SS Sangakkara Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="bats/retro/ss-classic-kw"><img src={cannonkw} alt={cannonkw}/></a>
                        <div className="title-p text-center">
                            <a href="bats/retro/ss-classic-kw">SS Cannon Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="bats/retro/ss-classic-kw"><img src={clubvellum} alt={clubvellum}/></a>
                        <div className="title-p text-center">
                            <a href="bats/retro/ss-classic-kw">SS Club Vellum Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="/bats/retro/ss-classic-gutsy"><img src={softpro} alt={softpro}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/retro/ss-classic-gutsy">SS Soft Pro Kashmir English Willow Cricket Bat</a>
                        </div>
                    </Col>
                </Row>
                <div className="mb-5"> </div>
            </Container>
        )
    }
}

export default SSRange;