import React, {Component} from "react";
import footerimg from "../../assets/images/footerimg.png";
import {Container} from "react-bootstrap";

class BatFooter extends Component{
    render() {
        return(
            <Container fluid={true} className="footerimg">
                <img src={footerimg} alt={footerimg}/>
            </Container>
        )
    }
}

export default BatFooter;