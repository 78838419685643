import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import {Helmet} from "react-helmet";
import HelmetCover from "../../components/Bats/HelmetCover/HelmetCover";
import gladiatorhelmet from "../../assets/images/products/Helmets/01. gladiator/helmet_gladiator.png";
import maximushelmet from "../../assets/images/products/Helmets/02. maximus/helmet_maximus.png";
import superhelmet from "../../assets/images/products/Helmets/03. super cricket/helmet_super.png";
import masterhelmet from "../../assets/images/products/Helmets/04. master/helmet_master.png";
import matrixhelmet from "../../assets/images/products/Helmets/05. matrix/helmet_matrix.png";
import gloryhelmet from "../../assets/images/products/Helmets/06. glory/helmet_glory.png";
import rangerhelmet from "../../assets/images/products/Helmets/07. ranger/helmet_ranger.png";
import professionalhelmet from "../../assets/images/products/Helmets/08. professional/helmet_professional.png";
import heritagehelmet from "../../assets/images/products/Helmets/09. heritage/helmet_heritage.png";
import elitehelmet from "../../assets/images/products/Helmets/10. elite/helmet_elite.png";
import slasherhelmet from "../../assets/images/products/Helmets/11. slasher/helmet_slasher.png";
import gutsyhelmet from "../../assets/images/products/Helmets/12. gutsy/helmet_gutsy.png";
import princehelmet from "../../assets/images/products/Helmets/13. prince/helmet_prince.png";
import princejuniorhelmet from "../../assets/images/products/Helmets/14. prince junior/helmet_prince_junior.png";

class Helmets extends Component{
    render() {
        return(
            <Container fluid={true}>
                <Helmet>
                    <title>Helmets - SS Cricket</title>
                </Helmet>
                <div>
                    <HelmetCover/>
                </div>
                <div className="text-center product-title hr">
                    <h1>HELMETS</h1>
                    <div className="pb-1 subtitle">
                        <h5>#BestNeverStops</h5>
                    </div>
                    <div className="pb-3 subtitle">
                        <h5>#LeadershipThroughQuality</h5>
                    </div>
                </div>
                <Row>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border align-content-center">
                        <a href="#"><img src={gladiatorhelmet} alt={gladiatorhelmet}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Gladiator Cricket Helmet</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={maximushelmet} alt={maximushelmet}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Maximus Cricket Helmet</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={superhelmet} alt={superhelmet}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Super Cricket Helmet</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={masterhelmet} alt={masterhelmet}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Master Cricket Helmet</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="#"><img src={matrixhelmet} alt={matrixhelmet}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Matrix Cricket Helmet</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={gloryhelmet} alt={gloryhelmet}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Glory Cricket Helmet</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={rangerhelmet} alt={rangerhelmet}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Ranger Cricket Helmet</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={professionalhelmet} alt={professionalhelmet}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Professional Cricket Helmet</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="#"><img src={heritagehelmet} alt={heritagehelmet}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Heritage Cricket Helmet</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={elitehelmet} alt={elitehelmet}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Elite Cricket Helmet</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={slasherhelmet} alt={slasherhelmet}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Slasher Cricket Helmet</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={gutsyhelmet} alt={gutsyhelmet}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Gutsy Cricket Helmet</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="#"><img src={princehelmet} alt={princehelmet}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Prince Cricket Helmet</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={princejuniorhelmet} alt={princejuniorhelmet}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Prince Junior Cricket Helmet</a>
                        </div>
                    </Col>
                </Row>
                <div className="mb-5"> </div>
            </Container>
        )
    }
}

export default Helmets;