import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import {Helmet} from "react-helmet";
import KitBagsCover from "../../components/Bats/KitBagsCover/KitBagsCover";
import gladiatorkb from "../../assets/images/products/kitbags/01.gladiator_1_4.png";
import maximuscamokb from "../../assets/images/products/kitbags/02.bag_maximus_camo_-1.png";
import playerkb from "../../assets/images/products/kitbags/03.players.png";
import maximuskb from "../../assets/images/products/kitbags/04.maximus.png";
import supremeton from "../../assets/images/products/kitbags/05.supreme_ton.png";
import playerduffle from "../../assets/images/products/kitbags/06.bag_players_duffle.png";
import limitededitionkb from "../../assets/images/products/kitbags/07.limited_edition.png";
import vertuton from "../../assets/images/products/kitbags/08.vertu_ton-2.png";
import slashertonkb from "../../assets/images/products/kitbags/09.slasher_ton.png";
import professionalkb from "../../assets/images/products/kitbags/10.professional_2.png";
import premiumdufflekb from "../../assets/images/products/kitbags/11.premium_duffle_6_sleeve.png";
import gutsytonkb from "../../assets/images/products/kitbags/12.gutsy_ton.png";
import tonduffle from "../../assets/images/products/kitbags/13.ton_duffle.png";
import elitebag from "../../assets/images/products/kitbags/14.bag_elite.png";
import blasterkb from "../../assets/images/products/kitbags/15.blaster.png";
import bagelitepro from "../../assets/images/products/kitbags/16.bag_elite_pro.png";
import camodufflekb from "../../assets/images/products/kitbags/17.camo_duffle_blue.png";
import slashercoltkb from "../../assets/images/products/kitbags/18.bag_slasher_colt.png";
import blastkb from "../../assets/images/products/kitbags/19.bag_blast.png";
import viperkb from "../../assets/images/products/kitbags/20.bag_viper.png";
import rangerkb from "../../assets/images/products/kitbags/21.bag_ranger.png";
import premiumkb from "../../assets/images/products/kitbags/22.premium.png";
import dufflegoldsilver from "../../assets/images/products/kitbags/23.bag_duffle_goldsilver.png";
import masterkb from "../../assets/images/products/kitbags/24.bag_master.png";
import coltkb from "../../assets/images/products/kitbags/25.bag_colt-2.png";
import heritagekb from "../../assets/images/products/kitbags/26.bag_heritage.png";
import superkb from "../../assets/images/products/kitbags/27.bag_super.png";
import customkb from "../../assets/images/products/kitbags/28.bag_custom.png";
import dufflecoltkb from "../../assets/images/products/kitbags/29.bag_duffle_colt-1.png";

class KitBags extends Component{
    render() {
        return(
            <Container fluid={true}>
                <Helmet>
                    <title>Kitbags - SS Cricket</title>
                </Helmet>
                <div>
                    <KitBagsCover/>
                </div>
                <div className="text-center product-title hr">
                    <h1>SS KITBAGS</h1>
                    <div className="pb-1 subtitle">
                        <h5>#BestNeverStops</h5>
                    </div>
                    <div className="pb-3 subtitle">
                        <h5>#LeadershipThroughQuality</h5>
                    </div>
                </div>
                <Row>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border align-content-center">
                        <a href="#"><img src={gladiatorkb} alt={gladiatorkb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Gladiator Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={maximuscamokb} alt={maximuscamokb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Maximum Camo Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={playerkb} alt={playerkb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Players Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={maximuskb} alt={maximuskb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Maximus Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="#"><img src={supremeton} alt={supremeton}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS TON Supreme Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={playerduffle} alt={playerduffle}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Players Duffle Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={limitededitionkb} alt={limitededitionkb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Limited Edition Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={vertuton} alt={vertuton}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS TON Vertu Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="#"><img src={slashertonkb} alt={slashertonkb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS TON Slasher Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={professionalkb} alt={professionalkb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Professional Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={premiumdufflekb} alt={premiumdufflekb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Premium Duffle Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={gutsytonkb} alt={gutsytonkb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS TON Gutsy Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="#"><img src={tonduffle} alt={tonduffle}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS TON Duffle Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={elitebag} alt={elitebag}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Elite Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={blasterkb} alt={blasterkb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Blaster Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={superkb} alt={superkb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS TON Super Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="#"><img src={bagelitepro} alt={bagelitepro}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Elite Pro Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={camodufflekb} alt={camodufflekb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Camo Duffle Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={slashercoltkb} alt={slashercoltkb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Slasher Colt Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={dufflegoldsilver} alt={dufflegoldsilver}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Duffle Gold/Silver Edition Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="#"><img src={blastkb} alt={blastkb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Blast Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={viperkb} alt={viperkb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Viper Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={rangerkb} alt={rangerkb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Ranger Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={premiumkb} alt={premiumkb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Premium Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="#"><img src={masterkb} alt={masterkb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Master Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={coltkb} alt={coltkb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Colt Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={heritagekb} alt={heritagekb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Heritage Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={superkb} alt={superkb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Super Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="#"><img src={customkb} alt={customkb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Custom Cricket Kit bag</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={dufflecoltkb} alt={dufflecoltkb}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Duffle Colt Cricket Kit bag</a>
                        </div>
                    </Col>
                </Row>
                <div className="mb-5"> </div>
            </Container>
        )
    }
}

export default KitBags;