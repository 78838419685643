import React, {Component} from "react";
import {Container, Row, Col} from "react-bootstrap";
import coverimg from "../../assets/images/Web Cover.png";
import Helmet from "react-helmet";
import kjp from "../../assets/images/players/new/kjp.jpg";
import lkflag from "../../assets/images/players/lk-flag.png";
import mathews from "../../assets/images/players/new/mathews.jpg";
import dasun from "../../assets/images/players/new/dasun.jpg";
import avishka from "../../assets/images/players/new/avishka.jpg";
import bhanuka from "../../assets/images/players/new/Bhanuka.jpg";
import dickwella from "../../assets/images/players/new/dickwella.jpg";
import dhanushka from "../../assets/images/players/new/danny.jpg";
import thirimanne from "../../assets/images/players/new/thirimanne.jpg";

class SSFamily extends Component {
    render() {
        return (
            <Container fluid={true} className="mainslide text-center pl-0 pr-0 ml-0">
                <Helmet>
                    <title>SS Family - SS Cricket</title>
                </Helmet>
                <div>
                    <img src={coverimg} alt={coverimg}/>
                </div>
                <div className="text-center product-title hr">
                    <h1>SS FAMILY</h1>
                    <div className="pb-1 subtitle">
                        <h5>#BestNeverStops</h5>
                    </div>
                    <div className="pb-3 subtitle">
                        <h5>#LeadershipThroughQuality</h5>
                    </div>
                </div>
                <Row>
                    <Col xs={12} sm={12} md={4} lg={{span: 2, offset: 2}}>
                        <div className="playerbox">
                            <div className="bottom">
                                <div>
                                    <img src={dasun} alt={dasun}/>
                                    <div className="flag">
                                        <img src={lkflag} alt={lkflag}/>
                                    </div>
                                </div>
                                <div className="left">
                                    <div className="details">
                                        <h1>Dasun Shanaka</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={2}>
                        <div className="playerbox">
                            <div className="bottom">
                                <div>
                                    <img src={bhanuka} alt={bhanuka}/>
                                    <div className="flag">
                                        <img src={lkflag} alt={lkflag}/>
                                    </div>
                                </div>
                                <div className="left">
                                    <div className="details">
                                        <h1>Bhanuka Rajapaksa</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={2}>
                        <div className="playerbox">
                            <div className="bottom">
                                <div>
                                    <img src={kjp} alt={kjp}/>
                                    <div className="flag">
                                        <img src={lkflag} alt={lkflag}/>
                                    </div>
                                </div>
                                <div className="left">
                                    <div className="details">
                                        <h1>Kusal Janith Perera</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={2}>
                        <div className="playerbox">
                            <div className="bottom">
                                <div>
                                    <img src={mathews} alt={mathews}/>
                                    <div className="flag">
                                        <img src={lkflag} alt={lkflag}/>
                                    </div>
                                </div>
                                <div className="left">
                                    <div className="details">
                                        <h1>Angelo Mathews</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={{span: 2, offset: 2}}>
                        <div className="playerbox">
                            <div className="bottom">
                                <div>
                                    <img src={dhanushka} alt={dhanushka}/>
                                    <div className="flag">
                                        <img src={lkflag} alt={lkflag}/>
                                    </div>
                                </div>
                                <div className="left">
                                    <div className="details">
                                        <h1>Dhanuskka Gunathilake</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    {/*<Col xs={12} sm={12} md={4} lg={{span: 2, offset: 2}}>*/}
                    {/*    <div className="playerbox">*/}
                    {/*        <div className="bottom">*/}
                    {/*            <div>*/}
                    {/*                <img src={malinga} alt={malinga}/>*/}
                    {/*                <div className="flag">*/}
                    {/*                    <img src={lkflag} alt={lkflag}/>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="left">*/}
                    {/*                <div className="details">*/}
                    {/*                    <h1>Lasith Malinga</h1>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                    <Col xs={12} sm={12} md={4} lg={2}>
                        <div className="playerbox">
                            <div className="bottom">
                                <div>
                                    <img src={avishka} alt={avishka}/>
                                    <div className="flag">
                                        <img src={lkflag} alt={lkflag}/>
                                    </div>
                                </div>
                                <div className="left">
                                    <div className="details">
                                        <h1>Avishka Fernando</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={2}>
                        <div className="playerbox">
                            <div className="bottom">
                                <div>
                                    <img src={dickwella} alt={dickwella}/>
                                    <div className="flag">
                                        <img src={lkflag} alt={lkflag}/>
                                    </div>
                                </div>
                                <div className="left">
                                    <div className="details">
                                        <h1>Niroshan Dickwella</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={2}>
                        <div className="playerbox">
                            <div className="bottom">
                                <div>
                                    <img src={thirimanne} alt={thirimanne}/>
                                    <div className="flag">
                                        <img src={lkflag} alt={lkflag}/>
                                    </div>
                                </div>
                                <div className="left">
                                    <div className="details">
                                        <h1>Lahiru Thirimanne</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    {/*<Col xs={12} sm={12} md={4} lg={2}>*/}
                    {/*    <div className="playerbox">*/}
                    {/*        <div className="bottom">*/}
                    {/*            <div>*/}
                    {/*                <img src={tharanga} alt={tharanga}/>*/}
                    {/*                <div className="flag">*/}
                    {/*                    <img src={lkflag} alt={lkflag}/>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="left">*/}
                    {/*                <div className="details">*/}
                    {/*                    <h1>Upul Tharanga</h1>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                    {/*<Col xs={12} sm={12} md={4} lg={2}>*/}
                    {/*    <div className="playerbox">*/}
                    {/*        <div className="bottom">*/}
                    {/*            <div>*/}
                    {/*                <img src={mathews} alt={mathews}/>*/}
                    {/*                <div className="flag">*/}
                    {/*                    <img src={lkflag} alt={lkflag}/>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="left">*/}
                    {/*                <div className="details">*/}
                    {/*                    <h1>Angelo Mathews</h1>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                </Row>
                <div className="mb-5"> </div>
            </Container>
        )
    }
}

export default SSFamily;
