import React, {Component} from "react";
import {Row, Container, Col, Nav, NavDropdown} from "react-bootstrap";

class Footer extends Component{
    render() {
        return(
            <Container fluid={true} className="footer">
                <Container>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <ul className="nav justify-content-center">
                            <Nav.Link href="/home">Home</Nav.Link>
                            <Nav.Link href="/products">Products</Nav.Link>
                            <Nav.Link href="/ss-family">SS Family</Nav.Link>
                            <Nav.Link href="/about-us">About us</Nav.Link>
                            <Nav.Link href="/contact-us">Contact us</Nav.Link>
                        </ul>
                        <Row>
                            <div className="container text-center">
                                <a href="https://www.facebook.com/SareenSports//"><i className="fab fa-facebook-square"/></a>
                                <a href="https://www.youtube.com/channel/UCVBuQLJ4r_AmywU0rYlCIsQ"><i className="fab fa-youtube-square"/></a>
                                <a href="https://www.instagram.com/jatin_sareen/"><i className="fab fa-instagram"/></a>
                                <a href="https://twitter.com/SareenSports"><i className="fab fa-twitter-square"/></a>
                            </div>
                            <div className="container text-center copyright-text">
                                <span>Copyright <i className="far fa-copyright"/> 2020 sscricket.lk. All Rights Reserved</span>
                            </div>
                        </Row>
                    </Col>
                </Container>
            </Container>
        )
    }
}

export default Footer;