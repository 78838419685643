import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import photo from "../../assets/images/cover vintage page.png";
import vintage1 from "../../assets/images/products/bats/Vintage Classic/01.vintage.png";
import vintage2 from "../../assets/images/products/bats/Vintage Classic/02.vintage_2.0.png";
import vintage3 from "../../assets/images/products/bats/Vintage Classic/03.vintage_3.0.png";
import vintage4 from "../../assets/images/products/bats/Vintage Classic/04.vintage_4.0.png";
import {Helmet} from "react-helmet";

class VintageClassic extends Component{
    render() {
        return(
            <Container fluid={true}>
                <Helmet>
                    <title>SS Vintage Classic Collection - SS Cricket</title>
                </Helmet>
                <div className="mainslide text-center pl-0 pr-0 ml-0">
                    <a href=""><img src={photo} alt={photo}/></a>
                </div>
                <div className="text-center product-title hr">
                    <h1>VINTAGE CLASSIC COLLECTION</h1>
                    <div className="pb-1 subtitle">
                        <h5>#BestNeverStops</h5>
                    </div>
                    <div className="pb-3 subtitle">
                        <h5>#LeadershipThroughQuality</h5>
                    </div>
                </div>
                <Row>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border align-content-center">
                        <a href="/bats/vintage/ew-1.0"><img src={vintage1} alt={vintage1}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/vintage/ew-1.0">SS Vintage 1.0 English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="/bats/vintage/ew-2.0"><img src={vintage2} alt={vintage2}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/vintage/ew-2.0">SS Vintage 2.0 English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="/bats/vintage/ew-3.0"><img src={vintage3} alt={vintage3}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/vintage/ew-3.0">SS Vintage 3.0 English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="/bats/vintage/ew-4.0"><img src={vintage4} alt={vintage4}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/vintage/ew-4.0">SS Vintage 4.0 English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    {/*<Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">*/}
                    {/*    <a href="/bats/retro/ss-classic-gutsy"><img src={rgutsy} alt={rgutsy}/></a>*/}
                    {/*    <div className="title-p text-center">*/}
                    {/*        <a href="/bats/retro/ss-classic-gutsy">SS Retro Classic Gutsy English Willow Cricket Bat</a>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                    {/*<Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">*/}
                    {/*    <a href="bats/retro/ss-classic-kw"><img src={rkwclassic} alt={rkwclassic}/></a>*/}
                    {/*    <div className="title-p text-center">*/}
                    {/*        <a href="bats/retro/ss-classic-kw">SS Retro Classic Kashmir Willow Cricket Bat</a>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                </Row>
                <div className="mb-5"> </div>
            </Container>
        )
    }
}

export default VintageClassic;