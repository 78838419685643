import React, {Component} from "react";
import {Container} from "react-bootstrap";
import helmetcover from "../../../assets/images/cover helmet.png";

class HelmetCover extends Component{
    render() {
        return(
            <Container fluid={true} className="mainslide text-center pl-0 pr-0 ml-0">
                <img src={helmetcover} alt={helmetcover}/>
            </Container>
        )
    }
}

export default HelmetCover;