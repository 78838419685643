import {IRoute} from "../interfaces/IRoute";
import { FE_ROUTES } from "../constants/routes";
import Home from "../layouts/Home";
import RetroRange from "../pages/RetroRange/RetroRange";
import SSRetroClassicSupreme from "../pages/RetroRange/SSRetroClassicSupreme";
import SSRetroClassicPowerPlus from "../pages/RetroRange/SSRetroClassicPowerPlus";
import SSRetroClassicMaxPower from "../pages/RetroRange/SSRetroClassicMaxPower";
import SSRetroClassicSuper from "../pages/RetroRange/SSRetroClassicSuper";
import SSRetroClassicGutsy from "../pages/RetroRange/SSRetroClassicGutsy";
import SSRetroClassicKashmirWillow from "../pages/RetroRange/SSRetroClassicKW";
import VintageClassic from "../pages/VintageClassic/VintageClassic";
import VintageClassic1 from "../pages/VintageClassic/VintageClassic1";
import VintageClassic2 from "../pages/VintageClassic/VintageClassic2";
import VintageClassic3 from "../pages/VintageClassic/VintageClassic3";
import VintageClassic4 from "../pages/VintageClassic/VintageClassic4";
import SSRange from "../pages/SSRange/SSRange";
import Gladiator from "../pages/SSRange/Gladiator";
import EWMaxiMus from "../pages/SSRange/EWMaxiMus";
import LimitedEditionEW from "../pages/SSRange/LimitedEditionEW";
import ProfessionalEW from "../pages/SSRange/ProfessionalEW";
import RangerEW from "../pages/SSRange/RangerEW";
import EWIBat from "../pages/SSRange/EW-I-Bat";
import TON47 from "../pages/SSRange/TON47";
import TONRange from "../pages/TONRange/TONRange";
import MasterRange from "../pages/MasterRange/MasterRange";
import WhiteEdition from "../pages/WhiteEdition/WhiteEdition";
import KitBags from "../pages/KitBags/KitBags";
import Helmets from "../pages/Helmets/Helmets";
import SSFamily from "../pages/SSFamily/SSFamily";
import Aboutus from "../pages/Aboutus";
import ContactUs from "../pages/ContactUs";
import Genuine from "../components/GenuineCheck/Genuine";

const indexRoutes: IRoute[] = [
    {path: FE_ROUTES.HOME, component:Home},
    {path: FE_ROUTES.SSFAMILY, component: SSFamily},
    {path: FE_ROUTES.ABOUTUS, component: Aboutus},
    {path: FE_ROUTES.CONTACTUS, component: ContactUs},
    {path: FE_ROUTES.RETRO, component:RetroRange},
    {path: FE_ROUTES.RETROCLASSICSUPREME, component: SSRetroClassicSupreme},
    {path: FE_ROUTES.RETROCLASSICPOWERPLUS, component: SSRetroClassicPowerPlus},
    {path: FE_ROUTES.RETROCLASSICMAXPOWER, component: SSRetroClassicMaxPower},
    {path: FE_ROUTES.RETROCLASSICSUPER, component: SSRetroClassicSuper},
    {path: FE_ROUTES.RETROCLASSICGUTSY, component: SSRetroClassicGutsy},
    {path: FE_ROUTES.RETROCLASSICKW, component: SSRetroClassicKashmirWillow},
    //Vintage Classic
    {path: FE_ROUTES.VINTAGECLASSIC, component: VintageClassic},
    {path: FE_ROUTES.VINTAGE1, component: VintageClassic1},
    {path: FE_ROUTES.VINTAGE2, component: VintageClassic2},
    {path: FE_ROUTES.VINTAGE3, component: VintageClassic3},
    {path: FE_ROUTES.VINTAGE4, component: VintageClassic4},
    //SS Range
    {path: FE_ROUTES.SSRANGE, component: SSRange},
    {path: FE_ROUTES.GLADIATOR, component: Gladiator},
    {path: FE_ROUTES.MAXIMUS, component: EWMaxiMus},
    {path: FE_ROUTES.LIMITEDEDITION, component: LimitedEditionEW},
    {path: FE_ROUTES.PROFESSIONAL, component: ProfessionalEW},
    {path: FE_ROUTES.RANGER, component: RangerEW},
    {path: FE_ROUTES.EWIBAT, component: EWIBat},
    {path: FE_ROUTES.EWTON47, component: TON47},

    // TON Rnage
    {path: FE_ROUTES.TONRANGE, component: TONRange},


    // Master Range
    {path: FE_ROUTES.MASTERRANGE, component: MasterRange},


    // White Edition
    {path: FE_ROUTES.WHITEEDITION, component: WhiteEdition},


    // Kit Bags
    {path: FE_ROUTES.KITBAGS, component: KitBags},


    // Helmets
    {path: FE_ROUTES.HELMETS, component: Helmets},

    {path: FE_ROUTES.ROOT, component:Home},
];

export default indexRoutes;