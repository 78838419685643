import React, {Component} from "react";
import cover from "../../assets/images/products/batcover.jpg";
import img1 from "../../assets/images/products/bats/Retro/gutsy-1.jpg";
import BatFooter from "../../components/BatFooter/BatFooter";
import Product from "../../components/Product/Product";

class SSRetroClassicGutsy extends Component {

    description = [
        "Selected grade 4 willow",
        "Round Handle",
        "Shape designed by professionals",
        "Specially designed Grip with Embossed Sunridges",
        "Combination of Sarawak cane Handle",
        "Concave Edge",
        "Huge edge super rebounded quality",
        "Made in india",
    ];

    render() {
        return (
            <React.Fragment>
                <Product metaTitle={"SS Retro Classic Gutsy English Willow Bat - SS Cricket"} coverPhoto={cover}
                         breadcrumbProductCat={"Retro Range"} breadcrumbProductCatUrl={"/retro-range"}
                         breadcrumbProduct={"SS Retro Classic Gutsy English Willow Bat"}
                         productImage={img1} productImageAlt={"SS Retro Classic Gutsy English Willow Bat Sri Lanka"}
                         productTitle={"SS RETRO CLASSIC GUTSY ENGLISH WILLOW BAT"} description={this.description}
                />
                <BatFooter/>
            </React.Fragment>
        )
    }
}

export default SSRetroClassicGutsy;