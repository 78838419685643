import React, {Component} from "react";
import {Container} from "react-bootstrap";
import coverimg from "../assets/images/cover-ss (1).png";
import Helmet from "react-helmet";

class Aboutus extends Component {
    render() {
        return (
            <Container fluid={true} className="mainslide text-center pl-0 pr-0 ml-0">
                <Helmet>
                    <title>About Us - SS Cricket</title>
                </Helmet>
                <div>
                    <img src={coverimg} alt={coverimg}/>
                </div>
                <div className="text-center product-title hr">
                    <h1>ABOUT US</h1>
                    <div className="pb-1 subtitle">
                        <h5>#BestNeverStops</h5>
                    </div>
                    <div className="pb-3 subtitle">
                        <h5>#LeadershipThroughQuality</h5>
                    </div>
                </div>
                <div className="text-justify text-ab">
                    <p>
                        Sareen Sports Industries was started in the year 1969 by Mr. N.K. Sareen under the inspiration of
                        his father Mr. M.L. Sareen, Sareen Sports Industries entered in the Indian market with a great
                        hard work and dedication to launch their registered Trade-Mark SS SUNRIDGES in the year 1976.
                        The company started export of cricket bats in 1979 to UK and Australia. Sareen Sports Industries
                        manufacturers quality cricket equipments for meeting the requirements of national and
                        international markets. The company also added on the production of Cricket Balls and Soft
                        Leather Protective Equipments under the flagship of “SS SUNRIDGES” in the year 1985.
                    </p>
                    <p>
                        As our SS Brand Cricket Equipments are known as a name of Quality goods are used by the
                        international cricket players like Saurav Ganguly, Virender Sehwag, Sachin Tendulkar, V.V.S
                        Laxman, Roger Binny, Manoj Prabhakar, and many other test cricketers S. Ramesh, Yuvraj Singh,
                        Akash Chopra, and Deep Das Gupta.
                    </p>
                    <p>
                        The company has a good share of export business in the world market exporting to Australia, UK,
                        USA, South Africa, Canada, Sri Lanka and Bangladesh. Our company is engaged in making the
                        product for international companies. Our product brands are TON L.E, TON ELITE, TON VA-999, TON
                        HERITAGE, TON CAMBRIDGE. Our Sunridges SS cricket equipment is used by international players
                        like Arvinda DeSilva, Lance Klusner, Alister Cambell, Ravindu Shah, Trevour, Hitesh Modi,
                        Chaminda Vaas, Hassan Tilakratne and many others.
                    </p>
                    <p>
                        The highest test runs scored by V.V.S. Laxman 281 and now 309 by Virender Sehwag at Multan in
                        Pakistan are by our Cricket Bat SS TON.
                    </p>
                </div>
                <div className="mb-5"></div>
            </Container>
        )
    }
}

export default Aboutus;