import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import {Helmet} from "react-helmet";
import MasterRangeCover from "../../components/Bats/MasterRangeCover/MasterRangeCover";
import master9000 from "../../assets/images/products/bats/Master/01.ew_master_9000.png";
import master7000 from "../../assets/images/products/bats/Master/02.ew_master_7000.png";
import master5000 from "../../assets/images/products/bats/Master/03.ew_master_5000.png";
import master2000 from "../../assets/images/products/bats/Master/04.ew_master_2000.png";
import master1500 from "../../assets/images/products/bats/Master/05.ew_master_1500.png";
import master1000 from "../../assets/images/products/bats/Master/06.ew_master_1000.png";
import master500 from "../../assets/images/products/bats/Master/07.ew_master_500.png";
import master from "../../assets/images/products/bats/Master/08.master.png";

class MasterRange extends Component{
    render() {
        return(
            <Container fluid={true}>
                <Helmet>
                    <title>Master Range - SS Cricket</title>
                </Helmet>
                <div>
                    <MasterRangeCover/>
                </div>
                <div className="text-center product-title hr">
                    <h1>MASTER RANGE</h1>
                    <div className="pb-1 subtitle">
                        <h5>#BestNeverStops</h5>
                    </div>
                    <div className="pb-3 subtitle">
                        <h5>#LeadershipThroughQuality</h5>
                    </div>
                </div>
                <Row>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border align-content-center">
                        <a href="#"><img src={master9000} alt={master9000}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Master 9000 English Willow Cricket Bat </a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={master7000} alt={master7000}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Master 7000 English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={master5000} alt={master5000}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Master 5000 English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={master2000} alt={master2000}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Master 2000 English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="#"><img src={master1500} alt={master1500}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Master 1500 English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={master1000} alt={master1000}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Master 1000 English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={master500} alt={master500}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Master 500 English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="#"><img src={master} alt={master}/></a>
                        <div className="title-p text-center">
                            <a href="#">SS Master English Willow Cricket Bat</a>
                        </div>
                    </Col>
                </Row>
                <div className="mb-5"> </div>
            </Container>
        )
    }
}

export default MasterRange;