import React, {Component} from "react";
import {Container} from "react-bootstrap";
import masterrangecover from "../../../assets/images/cover ss page.png";

class MasterRangeCover extends Component{
    render() {
        return(
            <Container fluid={true} className="mainslide text-center pl-0 pr-0 ml-0">
                <img src={masterrangecover} alt={masterrangecover}/>
            </Container>
        )
    }
}

export default MasterRangeCover;