import React from 'react';
import './App.scss';
import {createBrowserHistory} from "history";
import { Router } from "react-router-dom";
import PageLayout from "./layouts/PageLayout";

const App: React.FC = () => {
  const hist = createBrowserHistory();

  return (
      <Router history={hist}>
        <PageLayout/>
      </Router>
  );
};

export default App;
