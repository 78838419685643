import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import photo from "../../assets/images/cover ss retro page.png";
import retrosupreme from "../../assets/images/products/bats/Retro/supreme.png";
import powerplus from "../../assets/images/products/bats/Retro/powerplus.png";
import rmaxpower from "../../assets/images/products/bats/Retro/max-power.png";
import rsuper from "../../assets/images/products/bats/Retro/classic-super.png";
import rgutsy from "../../assets/images/products/bats/Retro/gutsy.png";
import rkwclassic from "../../assets/images/products/bats/Retro/retro-classic-kw.png";
import {Helmet} from "react-helmet";

class RetroRange extends Component{
    render() {
        return(
            <Container fluid={true}>
                <Helmet>
                    <title>SS Retro Classic Range - SS Cricket</title>
                </Helmet>
                <div className="mainslide text-center pl-0 pr-0 ml-0">
                    <a href=""><img src={photo} alt={photo}/></a>
                </div>
                <div className="text-center product-title hr">
                    <h1>RETRO RANGE</h1>
                    <div className="pb-1 subtitle">
                        <h5>#BestNeverStops</h5>
                    </div>
                    <div className="pb-3 subtitle">
                        <h5>#LeadershipThroughQuality</h5>
                    </div>
                </div>
                <Row>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border align-content-center">
                        <a href="/bats/retro/ss-classic-supreme"><img src={retrosupreme} alt={retrosupreme}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/retro/ss-classic-supreme">SS Retro Classic Supreme English Willow Cricket Bat </a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="/bats/retro/ss-classic-power-plus"><img src={powerplus} alt={powerplus}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/retro/ss-classic-power-plus">SS Retro Classic Power Plus English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="/bats/retro/ss-classic-max-power"><img src={rmaxpower} alt={rmaxpower}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/retro/ss-classic-max-power">SS Retro Classic Max Power English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="/bats/retro/ss-classic-super"><img src={rsuper} alt={rsuper}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/retro/ss-classic-super">SS Retro Classic Super English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset:2}} lg={{span: 2, offset:2}} className="product-thumb br-border">
                        <a href="/bats/retro/ss-classic-gutsy"><img src={rgutsy} alt={rgutsy}/></a>
                        <div className="title-p text-center">
                            <a href="/bats/retro/ss-classic-gutsy">SS Retro Classic Gutsy English Willow Cricket Bat</a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} className="product-thumb br-border">
                        <a href="bats/retro/ss-classic-kw"><img src={rkwclassic} alt={rkwclassic}/></a>
                        <div className="title-p text-center">
                            <a href="bats/retro/ss-classic-kw">SS Retro Classic Kashmir Willow Cricket Bat</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default RetroRange;