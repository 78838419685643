import * as React from "react";
import {Container, Row, Col, Button, Modal, Form, Spinner} from "react-bootstrap";
import Sticker from "../../assets/images/sticker.png";
import {useState} from "react";
import axios from "axios";

const Genuine: React.FC = (props) => {
  interface VerificationState {
    code: string;
    pin: string;
    success: boolean
  }

  const [verificationState, setVerificationState] = useState<VerificationState>({
    code: '',
    pin: '',
    success: false
  })

  // const [code, setCode] = useState<string>('');
  // const [pin, setPin] = useState<string>('');
  const [showInvalid, setShowInvalid] = useState(false);
  const [showValid, setShowValid] = useState(false);
  const [showIncorrect, setShowIncorrect] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleCodeChange = (event: any) => {
    setVerificationState({...verificationState, code: event.target.value})
  };

  const handlePinChange = (event: any) => {
    setVerificationState({...verificationState, pin: event.target.value})
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsProcessing(true);

    const data = {
      code: verificationState.code,
      pin: verificationState.pin,
      success: verificationState.success
    }

    axios
      .post('https://ssver.atmogroup.lk/logic.php', data)
      .then((res: any) => {
        setIsProcessing(true);

        // console.log(res.data);
        // if (res.data.success === false && res.data.msg === 'Invalid data') {
        //   setShowIncorrect(true);
        //   console.log('invalid data')
        // }

        if (res.data.success === false) {
          setShowInvalid(true);
          setIsProcessing(false);
          // console.log('invalid data')
        }

        if (res.data.success === true) {
          setShowValid(true);
          setIsProcessing(false);
          // console.log('code found')
        }

      })
      .catch((err: any) => {
        console.log(err);
        setIsProcessing(false);
      })
  };

  const handleClose = () => setShowInvalid(false);
  const handleCloseValid = () => setShowValid(false);
  const handleShow = () => setShowInvalid(true);

  return (
    <Container fluid={true} className="bg">
      <Container className="br-outline empty-space align-content-center">
        <div className="text-center title">
          <h1>PRODUCT GENUINE CHECK</h1>
        </div>
        <div className="sub-title">
          <p>To provide you best quality our products come with quality sticker seals.<br/>Each product has a SERIAL
            NUMBER and a PIN NUMBER which you can verify here.</p>
        </div>

        <Form onSubmit={handleSubmit}>
          <Form.Row>
            <Col xs={12} sm={12} md={3} lg={2} className="sticker text-center mt-2">
              <img src={Sticker} alt={Sticker}/>
            </Col>
            <Col lg={4}>
              <Form.Control type="text" name="serial-number" placeholder="Serial Number" maxLength={6}
                            onChange={handleCodeChange}/>
            </Col>
            <Col lg={4}>
              <Form.Control type="text" name="pin-number" placeholder="Pin Number" maxLength={7}
                            onChange={handlePinChange}/>
            </Col>
            <Col lg={2} className='submit-btn'>
              <Button variant="primary" type='submit' className='submit-btn' disabled={isProcessing}>
                VERIFY {isProcessing && <Spinner animation={'border'} variant={'light'} size={'sm'} />}
              </Button>
            </Col>
          </Form.Row>


          {/*<Col xs={12} sm={12} md={3} lg={3} className="textbox text-center mt-3">*/}
          {/*    <Form.Control type="text" name="serial-number" placeholder="Serial Number" maxLength={6}/>*/}
          {/*</Col>*/}
          {/*<Col xs={12} sm={12} md={3} lg={3} className="textbox text-center mt-3">*/}
          {/*    <Form.Control type="text" name="pin-number" placeholder="Pin Number" maxLength={7}/>*/}
          {/*</Col>*/}
          {/*<Col xs={12} sm={12} md={3} lg={3} className="submit mt-3">*/}
          {/*    <Button variant="primary" type='submit'>*/}
          {/*        VERIFY*/}
          {/*    </Button>*/}

          {/*<Modal show={showInvalid} onHide={handleClose}>*/}
          {/*  /!*<Modal.Header closeButton>*!/*/}
          {/*  /!*    <Modal.Title>FAKE</Modal.Title>*!/*/}
          {/*  /!*</Modal.Header>*!/*/}
          {/*  <Modal.Body>Invalid Data<br/>*/}
          {/*  </Modal.Body>*/}
          {/*  <Modal.Footer>*/}
          {/*    <Button variant="primary" onClick={handleClose}>*/}
          {/*      Ok*/}
          {/*    </Button>*/}
          {/*  </Modal.Footer>*/}
          {/*</Modal>*/}

          <Modal show={showInvalid} onHide={handleClose} centered>
            <Modal.Title>
              <div className="sa">
                <div className="sa-error">
                  <div className="sa-error-x">
                    <div className="sa-error-left"/>
                    <div className="sa-error-right"/>
                  </div>
                  <div className="sa-error-placeholder"/>
                  <div className="sa-error-fix"/>
                </div>
              </div>
            </Modal.Title>
            <Modal.Body>
              Sorry code not found in the system. Please Check with Your Purchasing Dealer <br/>
              <br/>
              SAREEN SPORTS INDUSTRIES – INDIA
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showValid} onHide={handleClose} centered>
            <Modal.Title>
              <div className="success-checkmark">
                <div className="check-icon">
                  <span className="icon-line line-tip"/>
                  <span className="icon-line line-long"/>
                  <div className="icon-circle"/>
                  <div className="icon-fix"/>
                </div>
              </div>
            </Modal.Title>
            <Modal.Body>
              <p>
                Dear Customer <label>“Congratulations”</label> You Have Bought a <label>Genuine SS Cricket
                Equipment</label> <br/>
                <br/>
                Thank you for choosing our Products! <br/>
                SAREEN SPORTS INDUSTRIES – INDIA <br/>
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={handleCloseValid}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/*</Col>*/}
        </Form>

      </Container>
    </Container>
  )
}


export default Genuine;
